<template>
<div>
    <div class="breadcrumb_header">
        <div class="breadcrumb">
        <h4 style="margin:0;padding-left: 10px;">NeXa</h4>
        <span class="divider"></span>
        <span>Contract Diligence</span>
        </div>
    <div class="open_sidebar" @click="toggleSidebar">
      <span class="material-symbols-rounded icon">keyboard_double_arrow_left</span>
      <p>Open Sidebar</p>
    </div>
  </div>
  <div class="row">
    <div class="col-md-1 mt-3">
        <div class="d-flex justify-content-start cursor-pointer back" @click="goBack">
      <span class="material-symbols-rounded" style="margin: 0;">arrow_back</span>
      <span style="margin: 0 8px;">Back</span>
    </div>
    </div>
    <div class="col-md-10 mt-5"> 
        <div class="left-container">
    <section class="tabs">
      <h4 class="tabs-title">Contract Diligence</h4>
      <p class="tabs-description">
       Enter your query or upload documents to start.
      </p>
    </section>
    <section class="query-section">
      <article class="case-file">
        <h4 class="case-file-title mb-3">Ask NeXa</h4>
        <div class="content">
          <div class="searchbar">
            <form class="entry">
              <label for="legal-question" class="visually-hidden">Enter specific legal questions or topics e.g., Search for precedents related to domestic violence</label>
              <input type="text" id="legal-question" class="legal-input" placeholder="Enter specific legal questions or topics e.g., Search for precedents related to domestic violence" aria-label="Enter specific legal questions or topics e.g., Search for precedents related to domestic violence">
              </form>
            <footer class="search-bar-footer">
            <div class="d-flex justify-content-between">
                <div class="toggledp" style="background:#fff;display:flex;align-items: center;">
               <span class="material-symbols-rounded icon-small">arrow_drop_down</span>
               <button class="country-button" type="button" @click="toggleCountry">Jurisdiction</button></div>
               <div>
              <span class="char-count">0 / 3000</span>
            </div>
            </div>
            
          </footer>
           <div v-if="isCountry" class="country-option">
              <ul>
                <li
                  v-for="(flag, countryCode) in filteredCountryImages"
                  :key="countryCode"
                  @click="selectCountry(countryCode)"
                  class="countryOption"
                >
                  <img :src="flag" alt="Country Flag" class="country-flag" />
                  <span>{{ countryCode }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </article>
    </section>
    <section class="upload-file">
        <h4>Upload Files</h4>
        <span class="sub-title mb-2">Your files will be securely stored in Nexa, accessible only to you and your workspace admins, and you can delete them at any time.</span>
      <div class="d-flex mb-3 mt-3">
                    <div style="width:50%;" class="me-2">
                        <FileInput
                        style="flex-grow: 1;"
                        :accept="['pdf']"
                        @input-files="$set(files, 0, $event)"
                    />
                    </div>
                    <div>
                        <div v-if="files.length > 0 && files[0].length > 0" style="width:400px;">
                            <FileProcess
                            style="width: 100%; max-height: 200px; overflow-y: auto;"
                            :accept="['pdf']"
                            :files="files[0]"
                            @output-files="$set(files, 1, $event)"
                        />
                        </div>
                        <div v-else class="right-container">
                            <div>
                            <p >Upload one or more particulars of claim and/or defenses to begin.</p>
                        </div>
                        </div>
                    </div>
                 </div>
                    <div class="d-flex mt-2 swift">
                    <div class="toggle me-2">
                        <div class="switch"><div class="button"></div></div>
                    </div>
                        Organize files into folders
                    </div>
                    <div class="d-flex justify-content-end mt-3">
                        <div class="upload" @click="uploadFile">
                        Generate Insights</div>
                      </div>
    </section>
        </div>
    </div>
    <div class="col-md-1">
    </div>
    </div>
</div>
</template>
<script>
import countryImages from './../../../src/assets/country/index';
import FileInput from './../../components/input/FileInput.vue';
import FileProcess from './../../components/input/FileProcess.vue';
export default {
    components: {
       FileInput, FileProcess
  },
    data(){
        return{
            loading:true,
            sidebarCollapsed: false,
            lawbotVisible:false,
            isCountry:false,
            countryImages,
            files: [[], []],
        }
    },
    computed: {
    filteredCountryImages() {
      return Object.entries(this.countryImages).filter(
        ([countryCode]) => countryCode !== 'USA' // Filter out USA
      ).reduce((acc, [key, value]) => {
        acc[key] = value;
        return acc;
      }, {});
    }
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 7000);
  },
    methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
        this.$router.push({ name: 'ExpertMode' });
        },
        toggleCountry(){
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show('attachfile');
    },
    hideAttachModal() {
      this.$bvModal.hide('attachfile');
    },
    uploadFile(){

    }
    }
}
</script>

<style scoped>
.page-content{
  padding: 0;
}
.breadcrumb_header{
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background: #fff;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span{
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  height: 40px;
  background: var(--primary);
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  overflow-y: auto; /* Allows vertical scrolling if content exceeds the height */
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}
</style>

<style scoped>
  .left-container {
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0px 2px 4px rgba(170, 171, 175, 0.6);
    display: flex;
    flex-direction: column;
    padding: 22px 24px;
    overflow: hidden;
  }

  @media (max-width: 991px) {
    .left-container {
      padding: 0 20px;
    } }

  .tabs {
    display: flex;
    flex-direction: column;
    font-family: Poppins, sans-serif;
    justify-content: center;
  }

  .tabs-title {
    color: #0e4485;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
  } .tabs-description {
    color: #86888d;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .query-section {
    margin-top: 32px;
    font: 16px/28px Poppins, sans-serif;
  }
.case-file {
    display: flex;
    flex-direction: column;
    color: #383a3e;
    font-weight: 500;
    justify-content: center;
  }

  .case-file-title {
    margin-bottom: 4px;
  }

  .content {
    color: #d1d2d5;
    font-weight: 400;
  }
.searchbar {
    border: 1px solid #f2f3f3;
    border-radius: 8px;
    background-color: #fafbfc;
    display: flex;
    flex-direction: column;
    padding: 22px 24px;
  }

  .entry {
    height: 60px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }.legal-input {
    background: none;
    width: 100%;
  }

  .line {
    border: 1px solid #000;
    height: 28px;
    align-self: flex-start;
  }

  .char-count {
    align-self: end;
    margin-top: 40px;
  } .upload-file {
    margin-top: 32px;
    flex-direction: column;
    justify-content: flex-start;
  }

  .upload-section {
    display: flex;
    flex-direction: column;
    color: #383a3e;
    font: 500 16px/28px Poppins, sans-serif;
  }
.upload-title {
    margin-bottom: 8px;
  }

  .upload-description {
    color: #86888d;
    font: 400 12px/24px Poppins, sans-serif;
  }

  .upload {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
  }
.upload-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #d1d2d5;
    border-radius: 8px;
    background-color: #fafbfc;
    min-width: 240px;
    padding: 27px 80px;
  }.upload-figure {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .upload-image {
    width: 32px;
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
  }
 .upload-caption {
    color: #0e4485;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
  }

  .upload-choose-file {
    color: #0e4485;
  }

  .upload-file-format {
    color: #86888d;
    font-size: 12px;
    font-weight: 400;
    margin-top: 5px;}

  .upload-instruction {
    background-color: #fafbfc;
    border-radius: 8px;
    min-width: 240px;
    padding: 58px 70px;
    box-shadow: 0px 0px 4px rgba(242, 243, 243);
    color: #86888d;
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
  }

  .storage-info {color: #86888d;
    font: 400 12px/24px Poppins, sans-serif;
    margin-top: 8px;
  }

  .folder-creation {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
    padding: 8px 16px;
    border-radius: 4px;
    justify-content: flex-start;
    flex-wrap: wrap;
  }.toggle {
    align-items: center;
    display: flex;
  }

  .toggle-switch {
    width: 40px;
    height: 20px;
    border-radius: 100px;
    background-color: #d1d2d5;
    position: relative;
  }
 .toggle-checkbox {
    opacity: 0;
    position: absolute;
  }

  .toggle-checkbox:checked + .toggle-switch {
    background-color: #0e4485;
  }

  .toggle-checkbox:checked + .toggle-switch::before {
    transform: translateX(20px);
  }

  .toggle-switch::before {
    content: '';
    width: 16px; height: 16px;
    border-radius: 100px;
    background-color: #fff;
    position: absolute;
    transition: transform 0.3s;
    box-shadow: 0px 2px 4px rgba(39, 39, 39, 0.1);
  }

  .toggle-label {
    font: 400 14px/24px Poppins, sans-serif;
    color: #383a3e;
  } .generate-button {
    align-self: end;
    border-radius: 4px;
    background-color: rgba(14, 68, 133, 0.1);
    color: #d1d2d5;
    padding: 4px 16px;
    margin-top: 32px;
    font: 500 16px Poppins, sans-serif;
  }
</style>
<style scoped>

.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  h5{
    color: var(--Primary-Blue, #0E4485) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.sub-title{
    color: var(--Neutral-Dark-Grey, #86888D);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
}
.right-container{
    height: 182px;
    width: 400px;
    display: flex;
padding: 0px 53px 0px 99px;
justify-content: flex-end;
align-items: center;
flex: 1 0 0;
border-radius: 8px;
background: var(--Neutral-Interface-Grey, #FAFBFC);
box-shadow: 0px 0px 4px 0px #F2F3F3;
p{
    color: var(--Neutral-Dark-Grey, #86888D) !important;

/* Small Paragraph */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 200% */
}
}

.toggle {
    display: flex;
    width: 40px;
  }.switch {
    flex-grow: 1;
    background: var(--Neutral-Mid-Grey, #d1d2d5);
    border-radius: 100px;
    padding: 2px;
    display: flex;
    align-items: center;}.button {
    width: 16px;
    height: 16px; background: var(--Neutral-White, #fff);
    border-radius: 100px;
    box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
    
  }
  .swift:hover{
    background: rgba(24, 144, 255, 0.10);
    display: flex;
    border-radius: 4px;
    padding: 8px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
  }
.upload{border-radius: 4px;
background: rgba(14, 68, 133, 0.10);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--mid-grey);
}
.upload:hover{
    background: var(--primary);
}

.country-option{
    margin-top: -8%;
    margin-left: 13%;
  left: 18%;
  width: 100px !important;
  background: #FAFBFC;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px;
  position: absolute;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.country-flag {
  width: 18px;
  height: 18px;
  margin-right:10px;
}
.countryOption{
  cursor: pointer;
 margin-bottom: 10px;
 span{
  color:#86888D;
  font-size:14px;
 }
}
.icon-small {
  color: #86888D;
  margin: auto;
  font-size: 18px;
}
.toggledp{
    border-radius: 4px;
border: 1px solid var(--Neutral-Light-Grey, #F2F3F3);
background: var(--Neutral-White, #FFF);     
}
.country-button{
    color: var(--Neutral-Dark-Grey, #86888D);
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
</style>